import { useQuery } from "react-query";
import {
  GetHockeyEventResponse,
  GetHockeyEventsResponse,
} from "../api/Descriptors";
import { HockeyEventsClient } from "../api/HockeyEvent";

const hockeyEventClient = new HockeyEventsClient();

export const useHockeyEvents = () => {
  // const queryClient = useQueryClient()

  const useHockeyEvent = (eventId: number | string) => {
    if (typeof eventId === "string") {
      eventId = parseInt(eventId);
      if (Number.isNaN(eventId)) {
        throw new Error(`hockeyEventId ('${eventId}') is not a number`);
      }
    }

    return useQuery<GetHockeyEventResponse, Error>({
      queryKey: ["hockey-events", eventId],
      queryFn: () => hockeyEventClient.get(eventId.toString()),
    });
  };

  const useAllHockeyEvents = () =>
    useQuery<GetHockeyEventsResponse, Error>({
      queryKey: ["hockey-events"],
      queryFn: () => hockeyEventClient.getAll(),
    });

  return {
    useHockeyEvent,
    useAllHockeyEvents,
  };
};
